/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Sage = {
    // All pages
    'common': {
      init: function() {

        /* detect when background image is loaded */

        $('.js-bg-image-loaded').imagesLoaded({background: true},
          function() {
            $('.js-ac-fadein').addClass('a--ac-fade-in');
          }
        );

        /* scroll effect */

        $(window).scroll(function() {
          console.log('scrolly');
          var opac = 1 - $(window).scrollTop() / 100;
          $('.js-scroll-fade').css('opacity', 1 - $(window).scrollTop() / 500);
          console.log(1 - $(window).scrollTop() / 1000);
        });

        // JavaScript to be fired on all pages
        // function resizeDiv() {
        //   vpw = $(window).width();
        //   vph = $(window).height();
        //   $('.project-page-header').css({'height': vph + 'px'});
        // }
        //
        // window.onresize = function(event) {
        //   resizeDiv();
        // };
        //
        // resizeDiv();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'company': {
      init: function() {
        // JavaScript to be fired on the about us page
        var waypoint = new Waypoint({
          element: document.getElementById('usa-map'),
          handler: function() {
            $('.market-state-on').attr('class', 'ms-on-is-scrolled');
            $('.market-state-off').attr('class', 'ms-off-is-scrolled');

            console.log($('.market-state'));
            console.log('Basic waypoint triggered');
          },
          offset: 'bottom-in-view'
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function($) {

    $teamContainer = $('#team-container');

    $.ajaxSetup({cache:false});

    $('.team-link').click(function() {

        var postLink = $(this).attr('href');
        $('.header').hide();
        $('.menu').hide();
        $('body').toggleClass('no-scroll');

        $teamContainer.toggleClass('is-open');
        $teamContainer.html('content loading');
        $teamContainer.load(postLink + ' .team-post-wrap');

        return false;

      });

    closeTeamModal = function(e) {
        e.preventDefault();
        console.log(e);
        $teamContainer.toggleClass('is-open');
        $('body').toggleClass('no-scroll');
        $('.header').show();
        $('.menu').show();
      };

      // scroller

      // $(window).scroll(function() {
      //     $(".page-header-featured-image").css({
      //     'opacity' : 1-(($(this).scrollTop())/500)
      //   });
      // });

      // process

      $('.opener').click(function() {

        var $el = $(this);

        // close all items but not current

        if(!$el.parent('.process-item-wrap').hasClass('current-pi')) {
          $('.process-item-wrap').find('.is-open').removeClass('is-open');
          $('.current-pi').removeClass('current-pi');
        }
        // $('.current-pi').removeClass('current-pi');

        $el.find('.process-item-overlay').toggleClass('is-open');
        $el.find('.circle').toggleClass('is-open');
        $el.parent('.process-item-wrap').toggleClass('current-pi');

      });

  });
