(function($) {
  $(document).ready(function() {
    var qsRegex;
    var buttonFilter;
    // projects, clean up for production
    function debounce(fn, threshold) {
      var timeout;
      return function debounced() {
        if (timeout) {
          clearTimeout(timeout);
        }
        function delayed() {
          fn();
          timeout = null;
        }
        setTimeout(delayed, threshold || 100);
      };
    }

    var $projectgrid = $('.js-project-grid').isotope({
      // options...
      itemSelector: '.project-grid__item',
      layoutMode: 'masonry',
      filter: function() {
        var $this = $(this);
        console.log('this', $this);
        var searchResult = qsRegex ? $this.text().match(qsRegex) : true;
        var buttonResult = buttonFilter ? $this.is(buttonFilter) : true;
        console.log('button result' + buttonResult);
        return searchResult && buttonResult;
      }
    });

    // init to all on load

    $projectgrid.imagesLoaded().progress(function() {
      //$projectgrid.isotope({filter: '.all'});
      buttonResult = '.all';
      $('*[data-filter=".all"]').addClass('is-active');
      $projectgrid.isotope();
    });

    $('.project-filter-item').on('click', function(e) {
      e.preventDefault();
      buttonFilter = $(this).attr('data-filter');
      console.log(buttonFilter);
      $('.project-filters').find('.is-active').removeClass('is-active');
      $(this).addClass('is-active');
      $projectgrid.isotope();
    });

    var $quicksearch = $('#quicksearch').keyup(debounce(function() {
      qsRegex = new RegExp($quicksearch.val(), 'gi');
      $projectgrid.isotope();
    }));
  });

  /* gallery grid on project pages */

  var $gallerygrid = $('.gallery-grid').imagesLoaded(function() {
    $gallerygrid.isotope({
      itemSelector: '.gallery-grid-item',
      layoutMode: 'masonry'
    });
  });

})(jQuery);
