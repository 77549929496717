/**

  Set up SmoothState.js for clean page transitions.

**/

(function($) {
  console.log('hello world 2');
  $('#js-smoothstate').smoothState({
    prefetch: true,
    cacheLength: 0,
    debug: true,
    onStart: {
      // How long this animation takes
      duration: 500,
      // A function that dictates the animations that take place
      render: function($container) {
        $('#js-smoothstate').fadeOut(500);
      }
    },
    onProgress: {
      // How long this animation takes
      duration: 0,
      // A function that dictates the animations that take place
      render: function($container) {
        console.log('loading');
      }
    },
    onReady: {
      duration: 0,
      render: function($container, $newContent) {
        console.log('works?');
      }
    }
  });
})(jQuery);
