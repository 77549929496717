(function($) {
  $(document).ready(function() {
    $('.btn1').on('click', function() {
      $('.navigation-overlay').toggleClass('is-open');
      $('body').toggleClass('no-scroll');
      console.log('clicked');
    });

    $('.menu').on('click', function() {
      $(this).toggleClass('open');
    });
  });
})(jQuery);
